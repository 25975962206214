<template>
  <div>
    <img :src="logo" alt="Logo" class="logo-small" />
    <form class="mt-4" ref="passwordupdateform" novalidate @submit.prevent="onSubmit">
      <h2 class="text-center">Password Update</h2>
      <p class="text-center">Please enter your new password here to reset your account. Make sure to choose a strong and
        secure password to
        protect your account
      </p>
      <div class="form-group">
        <label for="password">Password</label>
        <div class="input-with-icon">
          <input type="text" v-if="showPassword" class="form-control" v-model="user.password" id="password"
            name="password" @input="validatePassword" @keydown="preventLeadingSpace($event, 'password')"
            placeholder="Password" maxlength="20" />
          <input type="password" v-else class="form-control" v-model="user.password" id="password" name="password"
            @input="validatePassword" @keydown="preventLeadingSpace($event, 'password')" placeholder="Password"
             maxlength="20" />
          <span class="icon is-small is-right" @click="passwordtoggleShow">
            <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
          </span>
        </div>
        <div v-if="passwordError" class="error-message">{{ passwordError }}</div>
      </div>
      <div class="form-group">
        <label for="confirm_password">Confirm Password</label>
        <div class="input-with-icon">
          <input type="text" v-if="showConfirmPassword" v-model="user.confirm_password" class="form-control"
            id="confirm_password" name="confirm_password" placeholder="Confirm password" @keydown="preventLeadingSpace($event, 'confirm_password')"
            maxlength="20" />
          <input type="password" v-else v-model="user.confirm_password" class="form-control" id="confirm_password"
            name="confirm_password" placeholder="Confirm password"  maxlength="20" @keydown="preventLeadingSpace($event, 'confirm_password')"/>
          <span class="icon is-small is-right" @click="confirmpasswordtoggleShow" >
            <i class="fas" :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
          </span>
        </div>
      </div>

      <div class="d-inline-block w-100 mt-2">
        <button :disabled="isSubmitting" type="submit" class="btn btn-primary float-left mr-2">
          {{ isSubmitting ? 'Update' : 'Update' }}
        </button>
        <button type="reset" class="btn btn-primary float-left">Cancel</button>
      </div>
      <div>
        <span class="dark-color"> Don't have an account? </span>
        <a href="/auth/sign-in" class="iq-waves-effect pr-4"> Sign In </a>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import constant, { toastMessage } from "@/config/constant";
import "jquery-validation";

export default {
  name: "PasswordUpdate",
  props: ["password"],
  data() {
    return {
      isSubmitting: false, // Define isSubmitting in the data object
      showPassword: false,
      showConfirmPassword: false,
      user: {
        password: "",
        confirm_password: "",
      },
      encryptedToken: "",
      encryptedLinkType: "",
      message: "",
      passwordError: "",
      logo: require('@/assets/images/Logo-C9lab eyeWhite.png')
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.encryptedToken = urlParams.get("token");
    this.encryptedLinkType = urlParams.get("link_type");
    this.user.password = this.$props.password;

    $(this.$refs.passwordupdateform).validate({
      //Form Validation
      rules: {
        confirm_password: {
          required: true,
          equalTo: "#password",
        },
      },
      messages: {
        confirm_password: {
          required: "Confirm password is required",
          equalTo: "Passwords do not match",
        },
      },
    });
  },

  methods: {
    // First letter space not allowed
    preventLeadingSpace(event, field) {
      if (event.key === ' ' && (event.target.selectionStart === 0 || this.user[field] === '')) {
        event.preventDefault();
      } else {
        this.user[field] = event.target.value.trim();
      }
    },
    // Strong password
    validatePassword() {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/;
      if (!regex.test(this.user.password)) {
        this.passwordError = "Password should be  Min 5 chars, 1 letter, 1 number, 1 special";
      } else {
        this.passwordError = '';
      }
    },
    //Password eye icon show and hide
    passwordtoggleShow() {
      this.showPassword = !this.showPassword;
    },
    //Confirm password eye icon show and hide
    confirmpasswordtoggleShow() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    // Password update 
    onSubmit() {
      // First, validate the password
      this.validatePassword();
      // If there's an error, don't proceed
      if (this.passwordError) {
        return;
      }
      if (!$(this.$refs.passwordupdateform).valid()) return;
      this.isSubmitting = true; // Enable the submit button
      axios
        .post(
          constant.API_BASE_URL + "reset-password/",
          {
            token: this.encryptedToken,
            linkType: this.encryptedLinkType,
            password: this.user.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.$router.push({ name: "auth/sign-in" });
        })
        .catch((error) => {
          if (error.response.data) {
            this.message = error.response.data;
            this.icon = "error";
            toastMessage(this.icon, this.message);
          } 
          else {
            this.message = "something went wrong please try again";
            this.icon = "error";
            toastMessage(this.icon, this.message);
          }
        })
        .finally(() => {
          this.message = "something went wrong please try again";
            this.icon = "error";
            toastMessage(this.icon, this.message);
          this.isSubmitting = false; // Enable submit button
        });
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 12px;
}

.logo-small {
  display: none;
}

@media (max-width: 600px) {
  .logo-small {
    display: block;
    width: 30%;
    margin: 0 auto;
  }
}
</style>
